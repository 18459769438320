import {DataStoreApiBase} from '@jetCommon/data-stores-api/base.js';

export default class MunicipalitiesApiCommon extends DataStoreApiBase {
    static resourceName = 'municipalities';

    getMunicipalitiesChoices(params) {
        return this.apiGet('municipalities_choices/', {params});
    }

    getCAPChoices(params) {
        return this.apiGet('cap_choices/', {params});
    }
}
