import {ApiBase} from '@jetCommon/jpe-api/base.js';

export default class CompanySiteApiCommon extends ApiBase {
    static resourceName = 'company_sites';

    createCompleteSite(data) {
        return this.apiPost('create_complete_site/', data);
    }

    updateCompleteSite(id, data) {
        return this.apiPost(`${id}/update_complete_site/`, data);
    }
}
