import {ApiBase} from '@jetCommon/jpe-api/base.js';

export default class EmploymentVariationApiCommon extends ApiBase {
    static resourceName = 'employment_variations';

    getHireKindChoices() {
        return this.apiGet('hire_kind_choices/');
    }
    getWorkTimePatternChoices() {
        return this.apiGet('work_time_pattern_choices/');
    }
    getWorkModeChoices() {
        return this.apiGet('work_mode_choices/');
    }
    recordChanges(data) {
        return this.apiPost('record_changes/', data);
    }
}
