import {end} from '@jetCommon/api.js';
import AtecoApiCommon from '@jetCommon/data-stores-api/ateco.js';
import AuthApi from '@/api/auth.js';
import CCNLApiCommon from '@jetCommon/data-stores-api/ccnl.js';
import CompanyApi from '@/api/company.js';
import CompanyCCNLSettingsApiCommon from '@/api/company_ccnl_settings.js';
import CompanyDetailsApiCommon from '@/api/company_details.js';
import CompanySiteAddressApiCommon from '@/api/company_site_address.js';
import CompanySiteApiCommon from '@/api/company_site.js';
import CountriesApiCommon from '@jetCommon/data-stores-api/countries.js';
import EmploymentVariationsApi from '@/api/employment-variations.js';
import EmploymentsApi from '@/api/employments.js';
import MunicipalitiesApiCommon from '@jetCommon/data-stores-api/municipalities.js';
import PersonApiCommon from '@jetCommon/api/person.js';
import ProvincesApiCommon from '@jetCommon/data-stores-api/provinces.js';
import WorkerApi from '@/api/worker.js';
import WorkerHomeAddressApiCommon from '@/api/worker_home_address.js';
import WorkerResidentialAddressApiCommon from '@/api/worker_residential_address.js';
import WorkerVariationsApi from '@/api/worker-variations.js';

export const api = {
    end,
    ateco: new AtecoApiCommon(),
    auth: new AuthApi(),
    ccnl: new CCNLApiCommon(),
    companies: new CompanyApi(),
    companySiteAddresses: new CompanySiteAddressApiCommon(),
    workerResidentialAddresses: new WorkerResidentialAddressApiCommon(),
    workerHomeAddresses: new WorkerHomeAddressApiCommon(),
    countries: new CountriesApiCommon(),
    companyCCNLSettings: new CompanyCCNLSettingsApiCommon(),
    companyDetails: new CompanyDetailsApiCommon(),
    companySites: new CompanySiteApiCommon(),
    employmentVariations: new EmploymentVariationsApi(),
    workerVariations: new WorkerVariationsApi(),
    employments: new EmploymentsApi(),
    municipalities: new MunicipalitiesApiCommon(),
    persons: new PersonApiCommon(),
    provinces: new ProvincesApiCommon(),
    workers: new WorkerApi(),
};
