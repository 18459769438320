import {afterEachHandler, beforeEachHandler, commonRoutes} from '@jetCommon/router.js';
import {api} from '@/api';
import {createRouter, createWebHashHistory} from 'vue-router';
import {scrollBehavior} from '@jetCommon/router.js';

const routes = [
    ...commonRoutes,
    {
        path: '/',
        component: () => import('@/layouts/SidebarLayout.vue'),
        redirect: '/aziende', // TODO: remove once the home page is implemented
        children: [
            // {
            //     path: '',
            //     component: () => import('Xxx.vue'),
            //     name: '',
            // },
            {
                path: 'aziende',
                component: () => import('@/features/company/views/menu/CompaniesListView.vue'),
                name: 'companiesListView',
            },
            {
                path: 'aziende/:companyId',
                component: () => import('@/features/company/views/menu/DashboardView.vue'),
                name: 'dashboardView',
            },
            {
                path: 'aziende/:companyId/personale',
                component: () => import('@/features/company/views/WorkersListView.vue'),
                name: 'workersListView',
            },
            {
                path: 'aziende/:companyId/personale/:workerId/',
                component: () => import('@/features/company/views/WorkerDetailView.vue'),
                name: 'workerDetailView',
            },
            {
                path: 'aziende/:companyId/variazioni/:employmentVariationId',
                component: () => import('@/features/employment/views/EmploymentVariationEditView.vue'),
                name: 'employmentVariationEditView',
            },
            {
                path: 'aziende/:companyId/presenze-assenze',
                component: () => import('@/features/company/views/menu/AbsencesView.vue'),
                name: 'absencesView',
            },
            {
                path: 'aziende/:companyId/voci-retribuzione',
                component: () => import('@/features/company/views/menu/PayElementsView.vue'),
                name: 'payElementsView',
            },
            {
                path: 'aziende/:companyId/elaborazione-paghe',
                component: () => import('@/features/company/views/menu/PayrollView.vue'),
                name: 'payrollView',
            },
            {
                path: 'aziende/:companyId/report',
                component: () => import('@/features/company/views/menu/ReportsView.vue'),
                name: 'reportsView',
            },
            {
                path: 'aziende/:companyId/documenti',
                component: () => import('@/features/company/views/menu/DocumentsView.vue'),
                name: 'documentsView',
            },
            {
                path: 'aziende/:companyId/impostazioni-azienda',
                component: () => import('@/features/company/views/menu/CompanySettingsView.vue'),
                name: 'companySettingsView',
            },
            // {
            //     path: 'impostazioni-engine',
            //     component: () => import('Xxx.vue'),
            //     name: '',
            // },
        ],
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: () => '/404',
    },
];

const router = createRouter({
    scrollBehavior,
    history: createWebHashHistory(),
    routes,
});

router.beforeEach(async (to, from) => await beforeEachHandler(to, from, api));

router.afterEach(afterEachHandler);

export default router;
