import {ApiBase} from '@jetCommon/jpe-api/base.js';

export default class WorkerVariationApiCommon extends ApiBase {
    static resourceName = 'worker_variations';

    getMaritalStatusChoices() {
        return this.apiGet('marital_status_choices/');
    }

    getEducationLevelChoices() {
        return this.apiGet('education_level_choices/');
    }
}
