import {ApiBase} from '@jetCommon/jpe-api/base.js';

export default class CompanyDetailsApiCommon extends ApiBase {
    static resourceName = 'company_details';

    getLegalFormChoices() {
        return this.apiGet('legal_form_choices/');
    }

    getCCIAAChoices() {
        return this.apiGet('cciaa_choices/');
    }
}
